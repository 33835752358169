<template>
  <div id="main">
    <iframe
      ref="webpay"
      id="iframe-webpay"
      :src="iframeSrc"
      frameborder="0"
      scrolling="no"
      width="100%"
      :style="style"
    ></iframe>
    <configurable-footer app_id="227017" :language="lang" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getRegion } from "@/utils/getLocale";
import configurableFooter from "@/components/configurable-json/index.vue";
import { getLocale } from "@/utils/getLocale";
import { listenResize } from "@/utils/windowListener";

export default {
  components: {
    configurableFooter
  },
  data() {
    return {
      region: getRegion(),
      iframeSrc: "",
      style: "",
      lang: "",
      langMap: {
        en_US: "en",
        zh_CN: "zh-cn",
        ja_JP: "jp",
        ko_KR: "kr",
        zh_TW: "zh-tw",
      },
      tempHeight: "",
      tempLang: "",
    };
  },
  computed: {
    ...mapState(["data_size_listen"]),
  },
  watch: {
    data_size_listen: {
      handler(newVal, oldVal) {
        let s_height = this.$lg(newVal, "screenHeight", 0);
        if (newVal != oldVal && s_height) {
          let f_height = 0;
          // let f_height = this.$lg(this.$refs.footer, "$el.offsetHeight", 0); // 页脚高度
          if (s_height > this.tempHeight + f_height) {
            this.initIframeHeight(s_height - f_height);
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.autoLanguage();
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    listenResize((size) => {
      this.setInfo({
        key: "data_size_listen",
        val: size,
      });
    });

    window.addEventListener("message", (e) => {
      let _height = e.data.height || "";
      this.initIframeHeight(_height);

      let _lang = e.data.lang;
      this.lang = _lang;
      if (_lang && _lang != this.tempLang) {
        let _locale = this.langMap[_lang] || "en";
        this.setLanguage(_locale);
        this.tempLang = _lang;

        this.updateRouteQuery("lang", _lang);
      }
      // 跳转
      if (e.data.href) {
        this.linkJump(e.data.href);
      }
    });

    this.initIframeSrc();
  },
  methods: {
    ...mapMutations(["setInfo"]),
    initIframeHeight(_height) {
      if (_height && _height != this.tempHeight) {
        this.style = { height: `${_height}px` };
        this.tempHeight = _height;
      }
    },
    getData() {
      // if (location.hostname.indexOf("torchlight.xd.com") >= 0) {
        return {
          branch: "master",
          domain: "https://pay-torchlight.xd.com/?v=1"
        };
      // } else {
      //   return {
      //     branch: "test",
      //     domain: "https://webpay-test.xd-cf-2022.workers.dev",
      //     appId: 1111,
      //   };
      // }
    },
    initIframeSrc() {
      const parameter = this.getData();

      let src = parameter.domain;
      let branch = parameter.branch;

      const _query = this.$route.query;
      const queryKeys = Object.keys(_query);
      if (_query && queryKeys.length != 0) {
        src += src.includes('?') ? '&' : '?';
        queryKeys.forEach((key) => {
          src += `${key}=${_query[key]}&`;
        });
        // 未传递appId, 测试环境补全
        if(!queryKeys.includes('appId') && branch == 'test') {
          src += `appId=${parameter.appId}&`
        }

        src = src.slice(0, -1);
      } else {
        if(branch == 'test') {
          src += `?appId=${parameter.appId}`;
        }
      }

      this.iframeSrc = src;
    },
    autoLanguage() {
      // 查看用户配置语言
      let locale = this.$localStorage.get("torchligth:locale");
      if (!locale) {
        locale = getLocale();
      }
      this.$i18n.locale = locale;
    },
    setLanguage(locale) {
      this.$localStorage.set("torchligth:locale", locale);
      this.$i18n.locale = locale;
    },
    updateRouteQuery(key, value) {
      let { query } = this.$route;
      if (query[key] == value) return;
      this.$router.replace({
        query: { ...query, [key]: value },
      });
    },
    linkJump(href) {
      let a = document.createElement("a");
      a.setAttribute("href", href);
      a.setAttribute("id", "orderSubmit");
      // 防止反复添加
      if (document.getElementById("orderSubmit")) {
        document.body.removeChild(document.getElementById("orderSubmit"));
      }
      document.body.appendChild(a);
      a.click();
    },
  },
};
</script>


<style lang="scss" scoped>
#main {
  position: relative;
  overflow: hidden;

  #iframe-webpay {
    position: relative;
    width: 100%;
    height: 100vh;
    @media screen and (max-width: 1440px) {
      height: 1292px;
    }
    @media screen and (max-width: 812px) {
      height: 1243px;
    }
    @media screen and (max-width: 640px) {
      height: 1479px;
    }
  }
}
</style>
